<template>
  <PageContainer
    :title="$translations.promos.packages['available-packages'].session"
    :actions="actions"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <!-- Specialist -->
          <div class="specialist">
            <div
              class="content-title text-gray-50 margin-bottom-sm"
              v-text="$translations['appointment-summary'].specialist"
            />
            <template v-if="loadingSpecialist">
              <Skeleton
                class="margin-top"
                width="100%"
                height="21px"
                borderRadius="16px"
              />
            </template>

            <template v-else>
              <!-- specialist list -->
              <DropdownField
                :options="specialistDropdownOptions"
                @change-item="handleSpecialistChange"
                v-model="selectedSpecialist"
                v-if="isLengthGreaterThanOne(specialistsArr)"
                class="specialist-dropdown"
              >
                <template v-slot:input="slotProps">
                  <div
                    @click="slotProps.props.openDropdown"
                    class="field-control"
                  >
                    <div class="specialist-row">
                      <div
                        :style="{
                          'background-image': `url(${getSelectedSpecialistObject.picture})`
                        }"
                        class="specialist-row-image"
                      />
                      <div
                        class="specialist-row-content body"
                        v-text="getSelectedSpecialistObject.name"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:value="slotProps">
                  <div>
                    <div class="specialist-row">
                      <div
                        :style="{
                          'background-image': `url(${slotProps.option.picture})`
                        }"
                        class="specialist-row-image"
                      />
                      <div
                        class="specialist-row-content body"
                        v-text="slotProps.option.name"
                      />
                    </div>
                  </div>
                </template>
              </DropdownField>
              <!-- one specialist -->
              <div class="specialist-row margin-top" v-else>
                <div
                  :style="{
                    'background-image': `url(${getSelectedSpecialistObject.picture})`
                  }"
                  class="specialist-row-image"
                />
                <div
                  class="specialist-row-content body"
                  v-text="getSelectedSpecialistObject.name"
                />
              </div>
            </template>
          </div>

          <!-- Service -->
          <div class="service">
            <div
              class="content-title text-gray-50 margin-bottom-sm"
              v-text="$translations.promos.packages.service"
            />
            <!-- service loading -->
            <Skeleton
              class="margin-top"
              width="100%"
              height="21px"
              borderRadius="16px"
              v-if="loadingService"
            />
            <!-- service data -->
            <template v-else>
              <DropdownField
                v-if="isLengthGreaterThanOne(sliderOptions)"
                :options="sliderOptions"
                @change-item="setOption"
                v-model="selectedServiceOption"
                class="service-dropdown"
              />
              <div
                class="body margin-top"
                v-else
                v-text="getServiceDropdownInitVal"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="page-segment">
        <div class="page-segment-content">
          <!-- packages -->
          <template v-if="packages && !loadingPackage">
            <PackageCard
              class="margin-bottom-sm"
              v-for="(packageData, i) in packages.packages"
              :key="packageData.id + i"
              v-selectable-container="{ elementLevel: 1 }"
              :specialist-id="selectedSpecialist"
              :service-name="packages[`name_${lang}`]"
              :package-data="packageData"
              :currency="packageData.product_packages[0].price.currency"
              :show-button="true"
              :can-open-detail="{ modal: false, view: true }"
              :icon-name="packages.icon_name"
              :show-addi="true"
              :is-clickable="true"
              :button-text="$translations.promos.packages.buy"
            />
          </template>
          <template v-else>
            <Skeleton
              class="margin-bottom-sm"
              width="100%"
              height="180px"
              borderRadius="16px"
              v-for="index in 3"
              :key="index"
            />
          </template>
        </div>
      </div>
      <!-- FAQS -->
      <div class="segment">
        <div class="segment-content">
          <div
            class="small-tag padding-bottom-sm"
            v-text="$translations.promos['packages'].generic.faq"
          />
          <Accordion
            v-for="(question, key) in faq"
            :key="key"
            :text="question.title[lang]"
            :hasCustomContent="true"
            :is-collapsed="currentFaqIndex !== key"
            :custom-controller="true"
            @collapse="changeCurrentFaqIndex($event, key)"
          >
            <template v-slot:accordion>
              <div v-html="question.description[lang]"></div>
            </template>
          </Accordion>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  selectableContainer,
  Skeleton,
  Settings,
  Accordion,
  Promos
} from '@seliaco/red-panda'
import { PackageEvent } from '@/types/events'
import PackageCard from '@/views/promos/packages/components/PackageCard'
import { DropdownField } from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'

export default {
  name: 'Packages',
  components: {
    PageContainer,
    Skeleton,
    PackageCard,
    Accordion,
    DropdownField
  },
  directives: {
    selectableContainer
  },
  props: {
    origin: String,
    specialists: Array
  },
  data () {
    return {
      packages: null,
      services: null,
      sliderOptions: null,
      lang: null,
      faq: {},
      faqLoading: false,
      currentFaqIndex: null,
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          }
        }
      },
      selectedServiceOption: null,
      selectedSpecialist: null,
      specialistsArr: null,
      loadingPackage: true,
      loadingSpecialist: true,
      loadingService: true,
      specialistDropdownOptions: null,
      defaultServiceOption: {
        es: 'Terapia Individual',
        pt: 'Terapia Individual',
        en: 'Individual Therapy'
      }
    }
  },
  created () {
    this.lang = this.$store.getters.language.lang
    this.setSpecialists()
    this.getFAQ()
  },
  methods: {
    setSpecialists () {
      if (this.origin === 'home' && this.nextAppointment?.specialist?.id) {
        const specialistNextAppointment = {
          name: `${this.nextAppointment.specialist.first_name} ${this.nextAppointment.specialist.last_name}`,
          id: this.nextAppointment.specialist.id,
          picture: this.nextAppointment.specialist.profile_picture
        }

        this.specialistsArr = [specialistNextAppointment]
        this.setSpecialistsDropdownOptionsArray()
        return
      }

      if (!this.specialists || this.specialists == null) {
        Promos.Packages.getAllUserSpecialistsWithActivePackages()
          .then((response) => {
            this.specialistsArr = response.specialists
            this.setSpecialistsDropdownOptionsArray()
          })
          .catch((error) => console.log('Error: ', error))
        return
      }

      this.specialistsArr = this.specialists
      this.setSpecialistsDropdownOptionsArray()
    },
    setSpecialistsDropdownOptionsArray () {
      const image = require('@/assets/images/avatar.png')
      this.specialistDropdownOptions = this.specialistsArr.map((e) => {
        return {
          name: e.name,
          value: e.id,
          id: e.id,
          picture: e?.picture ? encodeURI(e?.picture) : image
        }
      })
      this.selectedSpecialist = this.specialistDropdownOptions[0].id
      this.getServices()
    },
    getServices () {
      const packageServiceName = 'listBySpecialistServices'
      // this.origin === 'home'
      //   ? 'listByUserSpecialist'
      //   : 'listBySpecialistServices'

      Promos.Packages[packageServiceName](this.selectedSpecialist)
        .then((response) => {
          if (!response.length || response.length === 0) {
            return this.$router.replace({
              name: 'ServicePackages',
              query: {
                ...this.$route.query
              }
            })
          }

          const sliderOptions = []
          response.forEach((element) => {
            sliderOptions.push({
              value: element[`name_${this.lang}`],
              label: element[`name_${this.lang}`],
              action: () => {
                this.$router.replace({
                  query: {
                    ...this.$route.query,
                    tab: element.id
                  }
                })
              },
              id: element.id
            })
          })

          this.sliderOptions = sliderOptions
          this.services = response
          this.selectedServiceOption = this.getServiceDropdownInitVal
          this.setPackages()
        })
        .catch((error) => {
          this.$toast({
            text: error || this.$translations.promos.packages.error,
            severity: 'error'
          })
        })
        .finally(() => {
          this.loadingSpecialist = false
          this.loadingService = false
        })
    },
    callSegmentEvent (tab) {
      const body = {
        user: this.$store.getters['auth/user'],
        tab: tab,
        origin: 'Specialist packages'
      }
      PackageEvent.Package_Tab_Click(body)
    },
    setPackages () {
      this.loadingPackage = true
      const tab = this.sliderOptions.find((e) => {
        if (e.value === this.selectedServiceOption) {
          return e
        }
      })

      if (tab) {
        this.packages = this.services.find((o) => o.id === tab.id)
      } else {
        this.packages = this.services[0] || null
      }

      this.packages.packages = this.packages.packages.sort(
        (a, b) => b.price - a.price
      )

      this.callSegmentEvent(this.packages.name_en)
      this.loadingPackage = false
    },
    getFAQ () {
      this.faqLoading = true
      Settings.read({
        columns: 'value',
        eq: {
          key: 'FAQ_GENERIC_PACKAGE_DETAIL'
        }
      })
        .then((response) => {
          if (response.data[0].value) {
            this.faq = JSON.parse(response.data[0].value)
          }
        })
        .finally(() => {
          this.faqLoading = false
        })
    },
    changeCurrentFaqIndex (value, key) {
      this.currentFaqIndex = value ? key : null
    },
    setOption (value) {
      this.selectedServiceOption = value.value
      this.setPackages()
    },
    isLengthGreaterThanOne (array) {
      if (!array) {
        return false
      }
      return array.length > 1
    },
    handleSpecialistChange () {
      this.loadingService = true
      this.loadingPackage = true
      this.packages = null
      this.getServices()
    }
  },
  computed: {
    getSelectedSpecialistObject: {
      get () {
        return this.specialistDropdownOptions.find(
          (e) => e.id === this.selectedSpecialist
        )
      },
      set (val) {
        return this.specialistDropdownOptions.find((e) => e.id === val)
      }
    },
    getServiceDropdownInitVal () {
      const initVal = this.sliderOptions.find(
        (e) => e.label === this.defaultServiceOption[this.lang]
      )

      if (!initVal) {
        return this.sliderOptions[0].value
      }
      return initVal.value
    },
    ...mapGetters({
      nextAppointment: 'nextAppointment'
    })
  }
}
</script>

<style lang="sass" scoped>
.specialist-dropdown
  ::v-deep
    .field-control:not(:placeholder-shown)
      padding-top: 11px !important
      padding-bottom: 8px !important

.service-dropdown
  ::v-deep
    .field-control:not(:placeholder-shown)
      padding-top: 15px !important
      padding-bottom: 8px !important

.specialist
  padding: 16px 0px
  gap: 8px
  box-shadow: 0px 1px 0px #EFF1F5
  &-dropdown

  &-row
    display: flex
    overflow: hidden
    ustify-content: flex-start
    &-image
      border-radius: 9999px
      background-color: var(--gray-10)
      height: 32px
      background-position: center
      background-size: cover
      width: 32px
      margin-right: 6px
    &-content
      align-self: center
      color: var(--gray-80)

.service
  padding: 16px 0px 16px 0px
  gap: 8px
</style>
